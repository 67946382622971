import React, { Component } from 'react'
import './About.css'

class About extends Component {


  render() {
    return (
      <div className="About">
        <div className="prof jumbotron jumbotron-fluid">
          <img className="prof-pic" src="about_prof.jpg"/>
          <div className="Container body">
            <h2>test</h2>
           </div>
        </div>
      </div>
      )
  }
}

export default About