import React, { Component } from 'react'
import './Header.css'

class Header extends Component {


  render() {
    return (
      <nav id="header" className=" navbar sticky-top">
        <h1>Stefan Grasu</h1>
      </nav>
    );
  }
}

export default Header